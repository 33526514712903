
import { defineComponent } from "vue";
import Quiz from "./components/Quiz.vue";

export default defineComponent({
	name: "App",
	components: {
		Quiz,
	},
	data() {
		return { restaurantCode: "" as string, autoRedirect: false, showQuiz: false, mode: "", transparentMode: false, matchInfo: "", language: "" };
	},
	mounted() {
		// disable Matomo Screenshots to later activate them, when screen is finished loading
		// window["_paq"].push(["HeatmapSessionRecording::disable"]);
		const parentNode = this.$el.parentNode as HTMLElement;

		if (parentNode && parentNode instanceof HTMLElement) {
			const restaurantCode = parentNode.getAttribute("restaurant-code");
			if (restaurantCode !== null) this.restaurantCode = restaurantCode;

			const autoRedirect = parentNode.getAttribute("auto-redirect");
			if (autoRedirect !== null) this.autoRedirect = autoRedirect === "true";

			const language = parentNode.getAttribute("language");
			if (language !== null) this.language = language;

			const transparentMode = parentNode.getAttribute("transparent-mode");
			if (transparentMode !== null) this.transparentMode = true;

			const mode = parentNode.getAttribute("mode");
			if (mode !== null) this.mode = mode;

			const matchInfo = parentNode.getAttribute("match-info");
			if (matchInfo !== null) this.matchInfo = matchInfo;
		}

	},
});
